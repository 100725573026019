exports.components = {
  "component---src-components-global-careers-jsx": () => import("./../../../src/components/Global/Careers.jsx" /* webpackChunkName: "component---src-components-global-careers-jsx" */),
  "component---src-components-global-dealers-jsx": () => import("./../../../src/components/Global/Dealers.jsx" /* webpackChunkName: "component---src-components-global-dealers-jsx" */),
  "component---src-components-global-press-release-jsx": () => import("./../../../src/components/Global/pressRelease.jsx" /* webpackChunkName: "component---src-components-global-press-release-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-become-a-dealer-jsx": () => import("./../../../src/pages/become-a-dealer.jsx" /* webpackChunkName: "component---src-pages-become-a-dealer-jsx" */),
  "component---src-pages-bookings-jsx": () => import("./../../../src/pages/bookings.jsx" /* webpackChunkName: "component---src-pages-bookings-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-dealers-jsx": () => import("./../../../src/pages/dealers.jsx" /* webpackChunkName: "component---src-pages-dealers-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-refund-policy-jsx": () => import("./../../../src/pages/refund-policy.jsx" /* webpackChunkName: "component---src-pages-refund-policy-jsx" */),
  "component---src-pages-scooters-aetos-100-jsx": () => import("./../../../src/pages/scooters/aetos-100.jsx" /* webpackChunkName: "component---src-pages-scooters-aetos-100-jsx" */),
  "component---src-pages-scooters-intercity-aeolus-jsx": () => import("./../../../src/pages/scooters/intercity-aeolus.jsx" /* webpackChunkName: "component---src-pages-scooters-intercity-aeolus-jsx" */),
  "component---src-pages-scooters-intercity-neo-jsx": () => import("./../../../src/pages/scooters/intercity-neo.jsx" /* webpackChunkName: "component---src-pages-scooters-intercity-neo-jsx" */),
  "component---src-pages-scooters-kollegio-jsx": () => import("./../../../src/pages/scooters/kollegio.jsx" /* webpackChunkName: "component---src-pages-scooters-kollegio-jsx" */),
  "component---src-pages-scooters-kollegio-neo-jsx": () => import("./../../../src/pages/scooters/kollegio-neo.jsx" /* webpackChunkName: "component---src-pages-scooters-kollegio-neo-jsx" */),
  "component---src-pages-scooters-kollegio-plus-jsx": () => import("./../../../src/pages/scooters/kollegio-plus.jsx" /* webpackChunkName: "component---src-pages-scooters-kollegio-plus-jsx" */),
  "component---src-pages-suppliers-jsx": () => import("./../../../src/pages/suppliers.jsx" /* webpackChunkName: "component---src-pages-suppliers-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-test-rides-jsx": () => import("./../../../src/pages/test-rides.jsx" /* webpackChunkName: "component---src-pages-test-rides-jsx" */)
}

